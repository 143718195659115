import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/technology/jelly-bean-android-4-1-sdk-new-features",
  "date": "2012-12-19",
  "title": "JELLY BEAN (ANDROID 4.1 SDK) NEW FEATURES",
  "author": "admin",
  "tags": ["technology", "android"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article I would like to discuss the new features of Jelly Bean, what has changed from the previous releases, and more."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`1.What's new with Jelly Bean`}</h2>
    <p>{`Android 4.2 has improved in speed and simplicity . A completely `}<strong parentName="p">{`new camera experience`}</strong>{` that’s beyond smart, a `}<strong parentName="p">{`new form of typing`}</strong>{` that helps you power through your messages, and much more.`}</p>
    <h3>{`1.1 One tablet, many users`}</h3>
    <p>{`With support for multiple users, you can give each person their own space. Everyone can have their own homescreen, background, widgets, apps and games – even individual high scores and levels! And since Android is built with multitasking at its core, it’s a snap to switch between users – no need to log in and out. But only available on tablets.`}</p>
    <h3>{`1.2. Share what’s on your phone on the big screen.`}</h3>
    <p>{`Jelly Bean allows devices to enable wireless display. You can share movies, YouTube videos, and anything that’s on your screen on an HDTV. Just connect a wireless display adapter to any HDMI-enabled TV to mirror what’s on your screen quickly and easily.`}</p>
    <h3>{`1.3 Daydream`}</h3>
    <p>{`Daydream lets your Android device display useful and delightful information when idle or docked. Show off your photo albums, get the latest news from Google Currents, and more.`}</p>
    <h3>{`1.4.Expandable, actionable notifications.`}</h3>
    <p>{`Just swipe down from the top of the screen to see all your notifications in one place. Late for a meeting or missed a call? Take action in an instant directly from the notifications shade.`}</p>
    <h3>{`1.5 Fast and smooth.`}</h3>
    <p>{`Jelly Bean's buttery graphics and silky transitions, make moving between home screens and switching between apps effortless, like turning pages in a book.`}</p>
    <p>{`More reactive and uniform touch responses mean you can almost feel the pixels beneath as your finger moves across the screen. Jelly Bean makes your Android device even more responsive by boosting your device's CPU instantly when you touch the screen, and turns it down when you don't need it to improve battery life.`}</p>
    <h3>{`1.6 Beam photos and videos`}</h3>
    <p>{`With Android Beam on Jelly Bean you can now easily share your photos and videos with just a simple tap, in addition to sharing contacts, web pages, YouTube videos, directions, and apps. Just touch two NFC-enabled Android devices back-to-back, then tap to beam whatever's on the screen to your friend.`}</p>
    <h3>{`1.7 Widgets work like magic`}</h3>
    <p>{`With Jelly Bean it's now even easier to personalize your home screen. As you place widgets on the screen, everything else automatically moves to make room. When they're too big, widgets resize on their own.`}</p>
    <h3>{`1.8 Accessibility`}</h3>
    <p>{`Enable screen magnification to easily zoom or pan the entire screen to get a closer look. Visually impaired users can now enter full-screen magnification with a triple-tap on the screen, and even type and interact with the device while zoomed in. Blind users can use Gesture Mode to reliably navigate the UI using touch and swipe gestures in combination with speech output.`}</p>
    <h3>{`1.9 Best of Google`}</h3>
    <h2>{`Live in the Now`}</h2>
    <p>{`Google Now brings you the information you need, when and where you need it, before you even ask. Google Now tells you today's weather before you start your day, or when the next train will arrive as you’re standing on the platform. With the latest version, you can see popular photo spots nearby, track packages easily, get information about upcoming movies, and much more.`}</p>
    <h2>{`A new look for Google Search.`}</h2>
    <p>{`It's easier than ever to quickly get answers, explore, and browse search results. Android also lets you search the web with your voice, and it's convenient for getting quick answers on the fly. It speaks back to you and is powered by the `}<a href="http://www.google.com/insidesearch/features/search/knowledge.html">{`Knowledge Graph`}</a>{`, bringing you a precise answer if it knows it, and precisely ranked search results, so you can always find out more.`}</p>
    <h3>{`1.10 Amazing Photo Sphere camera`}</h3>
    <p>{`With Android 4.2, you can snap pictures in every direction that come together into incredible, immersive photo spheres that put you right inside the scene. View your photo spheres right on your phone, share them on Google+ with your friends and family, or even add them to Google Maps for the world to see.`}</p>
    <h3>{`1.11 A smarter keyboard, now with Gesture Typing`}</h3>
    <p>{`With Gesture Typing – just glide your finger over the letters you want to type, and lift after each word. You don’t have to worry about spaces because they’re added automatically for you.`}</p>
    <p>{`The keyboard can anticipate and predict the next word, so you can finish entire sentences just by selecting suggested words. Power through your messages like never before.`}</p>
    <p>{`Android's dictionaries are now more accurate and relevant. With improved speech-to-text capabilities, voice typing on Android is even better. It works even when you don't have a data connection, so you can type with your voice everywhere you go.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      